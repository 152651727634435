<template>
    <v-app :class="$root.state.type()">
        <v-main>
            <NordWindMainPage v-if="$root.appType('nordwind')"
            />
            <MainPage v-if="$root.appType('cash')"
            />
            <AutoMainPage v-if="$root.appType('auto')"
            />
            <VtbMainPage
                v-if="$root.appType('vtb')"
            />
            <BankirosMainPage
                v-if="$root.appType('bankiros')"
                class="bankiros-page"
            />
            <TravelataMainPage
                v-if="$root.appType('travelata')"
                class="travelata"
            />
            <SouthwindMainPage
                v-if="$root.appType('southwind')"
                class="southwind"
            />
            <FormHTML :step="step"/>
<!--            <FormHTML v-if="!checkStep" />-->
        </v-main>
    </v-app>
</template>

<script>
import MainPage from './components/MainPage';
import NordWindMainPage from './components/NordWindMainPage.vue';
import VtbMainPage from './components/VtbMainPage.vue';
import BankirosMainPage from "@/components/bankiros/MainPage.vue";
import AutoMainPage from "@/components/auto/MainPage.vue";
import FormHTML from "@/components/modal/FormHTML.vue";
import TravelataMainPage from "@/components/TravelataMainPage.vue";
import SouthwindMainPage from "@/components/SouthwindMainPage.vue";
import moment from "moment-timezone";

export default {
    name: 'App',
    components: {
        FormHTML,
        BankirosMainPage,
        MainPage,
        NordWindMainPage,
        TravelataMainPage,
        VtbMainPage,
        AutoMainPage,
        SouthwindMainPage
    },
    data(){
        return {
            // step: null
        }
    },
    computed: {
        connector(){
            return this.$root.connector;
        },
        anketa(){
            return this.$root.anketa;
        },
        checkStep(){
            let hasStep = false
            let url = location.href;
            if(url.includes('appClientId') && this.step){
                hasStep = true;
            }else {
                hasStep = false;
            }
            return hasStep
        },
        step(){
            return this.$root.state.esiaStatus.step
        },
        state(){
            return this.$root.state;
        }

    },

    methods:{
        checkEsia(){
            let url = location.href;
            let splitingUrl = url.split('/?')
            // let isIncludesSubstring = ()=> splitingUrl
            if(splitingUrl.length > 2 && url.includes('appClientId')){
                splitingUrl = `${splitingUrl[0]}?${splitingUrl[1]}&${splitingUrl[2]}`;
                location.replace(splitingUrl);
            }
            // console.log('url', splitingUrl)
            let esiaData = {
                sessionId: localStorage.getItem('sessionId'),
                token: localStorage.getItem('comundaToken'),
                tokenDt: localStorage.getItem('comundaTokenDt'),

            }
            this.connector.startProccess('esia4', esiaData)
                .then((response) => {
                    if(response.resultProcess.success){
                        this.anketa.juicyData = response.resultProcess.anketInfo?.juicyData;
                        this.anketa.applicationId = response.resultProcess.applicationId;
                        this.$root.state.esiaStatus.updateStep(response.resultProcess.step)
                        this.anketa.isOpenModal = true
                        this.anketa.phone.value = response.resultProcess.anketInfo.phoneNumber;
                        this.anketa.initialPayment.value = response.resultProcess.anketInfo.requestedCreditAmount

                        let esiaData = {
                            step: response.resultProcess.step,
                            esiaClientId: localStorage.getItem('sessionId'),
                            sessionId: localStorage.getItem('sessionId'),
                            token: localStorage.getItem('comundaToken'),
                            tokenDt: localStorage.getItem('comundaTokenDt')
                        }
                        this.connector.startProccess('esia6', esiaData)
                    }
                })
        },
        getJuicyData(){

            let juicyData = {
                TimeZone: (0 - new Date().getTimezoneOffset()) / 60,
                UserAgent: navigator.userAgent,
                Cookie: this.$root.juicySession,
                TimeLocal: moment().format('YYYY-MM-DDTHH:mm:ss'),
                TimeMsk: moment.tz('Europe/Moscow').format('YYYY-MM-DDTHH:mm:ss'),
                IsJavascriptEnabled: 1,
                Channel: "SITE",
                IPAddress: null
            }
            this.connector.getUserInfo()
                .then(response => {
                    if(response){
                        juicyData.IPAddress = response?.ip
                    }
                })
            if(!juicyData.Cookie){
                this.anketa.juicyData = null
            }else {
                this.anketa.juicyData = juicyData
            }
        }
    },

    mounted() {
        setTimeout(()=> {
            this.getJuicyData()
        },800)
        if(location.href.includes('appClientId')){

            this.checkEsia()
        }


        this.$nextTick(() => {
            window.addEventListener('resize', () => {
                this.$root.anketa.windowWidth = window.innerWidth
            });
        })

        setTimeout(() => {
            this.$root.mindbox("Пробыл на сайте более 30 сек");
        }, 30000)
    },
};
</script>

<style lang="scss" scoped>

</style>
