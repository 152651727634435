<template>
    <div>
        <div class="main-option-for-app text-left">
            <h1 class="vtb_forms_title">Заявка отклонена</h1>
            <p class="vtb_declined_text vtb_main-color mt-3 mb-4 red--text">К сожалению, вам отказано в предоставлении сервиса
                <img class="vtb_declined_img" src="@/assets/img/pngegg (1).png" alt="sad_emoji">
            </p>
            <div>
                <p class="vtb_declined_text grey--text m-0">
                    Но вы можете получить деньги у наших партнёров.
                </p>
                <p class="vtb_declined_text">
                    Уровень одобрения по вашей заявке будет составлять
                    <span class="vtb_second-color">96%</span>
                </p>
            </div>
            <a class="vtb_declined_link" href="https://money-dam.ru/?lg_uid=eb4d2722-4c6a-412a-9128-cef03debd422&lg_ut=1728389729">
                <MainButton>Одобренные предложения</MainButton>
            </a>
        </div>
    </div>
</template>

<script>


import MainButton from "@/components/global/MainButton.vue";

export default {
    name: "VtbDeclined",
    components: {MainButton},
    computed: {

    },
    mounted() {
        this.$root[`metrika${this.$root.addYandexMetricGoals()}`].reachGoal("reject");
        this.$root.mindbox("Получил отказ по заявке");
    }
}
</script>

<style lang="scss" scoped>
//@import '../../assets/scss/_vtb-variables.scss';

.vtb {
    &_declined {
        &_text {
            //@include tr-text(16px, 400);

        }
        &_container {
            display: flex;
            gap: 10px;
            align-items: center;
        }
        &_img {
            width: 16px;
            height: 16px;
            margin-left: 10px;
        }
        &_link {
            text-decoration: none !important;
        }
    }
    &_main-color {
        color: #1976D2 !important;
    }
    &_second-color {
        color: #1976D2 !important;
    }
    //&_declined {
    //    @include tr-text(24px, 800, 28px);
    //    font-family: 'OpenSansEB';
    //    color: #333;
    //}
}
</style>
