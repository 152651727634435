<template>
    <div>
        <div class="main-option-for-app">
            <h1
                v-if="$root.appType('cash') || $root.appType('auto')"
                class="forms_title mb-8"
                >Авторизуйтесь или зарегистрируйтесь
            </h1>
<!--            <h1-->
<!--                v-if="$root.appType('vtb')"-->
<!--                class="vtb_form_title mb-8"-->
<!--                >Авторизуйтесь или зарегистрируйтесь-->
<!--            </h1>-->
<!--            <h1-->
<!--                v-if="$root.appType('travelata') && !isSizeMd"-->
<!--                class="travelata_form_title mb-8"-->
<!--                >Авторизуйтесь или зарегистрируйтесь-->
<!--            </h1>-->
<!--            <h1-->
<!--                v-if="$root.appType('travelata') && isSizeMd"-->
<!--                class="travelata_form_title mb-8"-->
<!--                >Регистрация клиента-->
<!--            </h1>-->
            <h1
                v-if="(!$root.appType('cash') && !$root.appType('auto')) && !isSizeMd"
                :class="`${$root.state.type()}_forms_title`"
                class="mb-8"
                >Авторизуйтесь или зарегистрируйтесь
            </h1>
            <h1
                v-if="(!$root.appType('cash') && !$root.appType('auto')) && isSizeMd"
                :class="`${$root.state.type()}_forms_title`"
                class="form_title mb-8"
                >Регистрация клиента
            </h1>
        </div>
        <v-form @submit.prevent ref="form">
            <!-- <FieldsCard v-if="!showRegisterCodeField || showFieldBeforeExist"  :anketaNames="['phone']" /> -->
            <FieldsCard :anketaNames="['phone']" />

            <!-- <div v-if="clientExists && isSmsForm" class="helper">
                <span class="helper-text" @click="changeTemplate(true)"></span>
                <span @click="callSopd" class="helper-text"
                    >Отправить код повторно
                </span>
            </div> -->

<!--            <v-btn-->
<!--                @click="goAuth"-->
<!--                :loading="loading"-->
<!--                :disabled="loading"-->
<!--                :class="!$root.appType('vtb') ? 'rectangle-button' : 'vtb-button'"-->
<!--                class="mt-6"-->
<!--                >-->
<!--                {{ !clientExists ? "Далее" : "Отправить" }}-->
<!--            </v-btn>-->
            <MainButton
                @click="goAuth"
                :loading="loading"
                :disabled="loading"
                >{{ !clientExists ? "Далее" : "Отправить" }}
            </MainButton>

        </v-form>
    </div>
</template>

<script>
import FieldsCard from "@/components/FieldsCard";
import MainButton from "@/components/global/MainButton";

export default {
    name: "Phone",
    components: { FieldsCard, MainButton },
    data: () => ({
        loading: false,
        clientExists: false,
        errorAuthTextApi: {
            status: false,
            text: "",
        },
    }),
    computed: {
        connector() {
            return this.$root.connector;
        },
        anketa() {
            return this.$root.anketa;
        },
        isSizeMd(){
            return this.$vuetify.breakpoint.mdAndDown;
        },
        getRates(){
            console.log('getRates init')
            let sliderTermValue = this.anketa.term.valueTerm
            console.log('sliderTermValue', sliderTermValue);
            console.log()
            let stock = this.listStocks.find(stock => stock.period === sliderTermValue)
            console.log('stock', stock)
            return stock
        },


    },
    methods: {
        goAuth() {
            if (this.$refs.form.validate()) {
                this.loading = true;
                if (this.clientExists) {
                    this.auth();
                } else {
                    this.checkExists();
                }
            }
        },

        checkExists() {
            this.connector
                .clientExists(this.anketa.phone.cleanValue())
                .then((response) => {
                    if (response.success && response.clientExists) {
                        // this.isSmsForm = true
                        // return (this.clientExists = true);

                        const stepData = {
                            success: true,
                            prev: "phone",
                            next: "auth",
                            // clientExist: response.clientExist
                        };

                        // let rates = {
                        //     rateId: this.getRates.rateId,
                        //     percent: this.getRates.percent,
                        //     period: this.getRates.period,
                        // }
                        let esiaData = {
                            step: 4,
                            anketInfo: {
                                // partnerNumber: this.getRates.shopId,
                                phoneNumber: this.anketa.phone.valueFirstDigitIs9(),
                                // flagEsia: true,
                                // juicyData: this.anketa.juicyData,
                                // rates: [rates],
                                requestedCreditAmount: this.anketa.initialPayment.value
                            },
                        }
                        this.connector.startProccess("esia1", esiaData)
                            .then((response) => {
                                console.log('phone startProcess response', response);
                                if (response.resultProcess.success) {
                                    // JSON.stringify(localStorage.setItem('sessionId', response.resultProcess.sessionId))
                                    // JSON.stringify(localStorage.setItem('comundaToken', response.resultProcess.token))
                                    // JSON.stringify(localStorage.setItem('comundaTokenDt', response.resultProcess.tokenDt))
                                    localStorage.setItem('sessionId', response.resultProcess.sessionId)
                                    localStorage.setItem('comundaToken', response.resultProcess.token)
                                    localStorage.setItem('comundaTokenDt', response.resultProcess.tokenDt)
                                }
                            })
                        // console.log('exist=true esia1', )
                        // this.clientExist = false
                        this.$emit("changeTemplate", stepData);
                    } else if(response.success && !response.clientExists){
                        console.log('client exists')
                        this.clientExists = false
                        const stepData = {
                            success: true,
                            prev: "phone",
                            next: "registration",
                        };
                        // console.log('getRates', this.getRates)
                        // let rates = {
                        //     rateId: this.getRates.rateId,
                        //     percent: this.getRates.percent,
                        //     period: this.getRates.period,
                        // }
                        // console.log('rates', rates)
                        this.anketa.phone.disabled = true
                        let esiaData = {
                            step: 2,
                            anketInfo: {
                                // partnerNumber: this.getRates.shopId,
                                phoneNumber: this.anketa.phone.valueFirstDigitIs9(),
                                // flagEsia: true,
                                // juicyData: this.anketa.juicyData,
                                // rates: [rates],
                                requestedCreditAmount: this.anketa.initialPayment.value
                            },
                        }
                        this.connector.startProccess("esia1", esiaData)
                            .then((response) => {
                                console.log('phone startProcess response', response);
                                if (response.resultProcess.success) {
                                    // JSON.stringify(localStorage.setItem('sessionId', response.resultProcess.sessionId))
                                    // JSON.stringify(localStorage.setItem('comundaToken', response.resultProcess.token))
                                    // JSON.stringify(localStorage.setItem('comundaTokenDt', response.resultProcess.tokenDt))
                                    localStorage.setItem('sessionId', response.resultProcess.sessionId)
                                    localStorage.setItem('comundaToken', response.resultProcess.token)
                                    localStorage.setItem('comundaTokenDt', response.resultProcess.tokenDt)
                                }
                            })
                        console.log('phone stepData', stepData)
                        this.$emit("changeTemplate", stepData);
                    }


                })
                .catch((error) => {
                    this.errorAuthTextApi.status = true;
                    if (error.message === "Network Error") {
                        this.errorAuthTextApi.text =
                            "Произошла невообразимая ситуация!";
                    }
                    // else {
                    //     this.$emit("changeTemplate", stepData);
                    // }
                })
                .finally(() => (this.loading = false));
        },
    },

};
</script>

<style lang="scss" scoped>
    .phone {
        &_title {
            font-size: 16px !important;
        }
    }
</style>
