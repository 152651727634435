<template>
  <!--form-->
    <v-navigation-drawer
        class="form-modal"
        id="form-modal"
        temporary
        touchless
        fixed
        right
        :permanent="anketa.fullScreenModal"
        v-model="anketa.isOpenModal"
        >
        <div
            :class="{ fullScreenModal: anketa.fullScreenModal }"
            class="form-display"
            >
            <div class="modal-navbar mb-8">
                <div
                    class="modal-icon modal-back"
                    @click="backPrevPage"
                    v-if="backPrevButtonRules"
                    >
                    <v-btn icon>
                        <img src="../../assets/img/arrow.svg" alt="logotype" />
                    </v-btn>
                </div>

                <v-btn
                    fab
                    elevation="1"
                    class="modal-icon modal-close btn_round"
                    @click="anketa.isOpenModal = false"
                    >
                    <img src="../../assets/img/close.svg" alt="logotype" />
                </v-btn>
            </div>

    <!-- Акция \ Нет акции + счет и карта -->
            <div class="modal-screen modal-screen-1">
                <Modals
                    :router="router"
                    :step="step"
                />
            </div>
        </div>
    </v-navigation-drawer>
</template>

<script>
import Modals from "./Modals.vue";
// import { state } from '@/mixins/state';
export default {
    components: { Modals },
    name: "FormHTML",
    data: () => ({
        // router: {
        //     phone: false,
        //     account: false,
        //     registration: false,
        //     auth: false,
        //     statusWait: false,
        //     recoveryPass: false,
        //     needMoreData: false,
        //     accessApplication: false,
        //     declined: false,
        //     smsPage: false,
        //     back: false,
        //     checkApplication: false,
        //     history: "",
        // },

        router: {
            phone: {
                status: false,
                step: 1
            },
            account: {
                status: false,
                step: 7
            },
            registration: {
                status: false,
                step: 2
            },
            auth: {
                status: false,
                step: 4
            },
            statusWait: {
                status: false,
                step: 5
            },
            recoveryPass: {
                status: false,
                step: 6
            },
            needMoreData: {
                status: false,
                step: 3
            },
            accessApplication: {
                status: false,
                step: 8
            },
            declined: {
                status: false,
                step: 9
            },
            smsPage: {
                status: false,
                step: 10
            },
            back: {
                status: false,
                step: null
            },
            checkApplication: {
                status: false,
                step: 11
            },
            waitingEsia: {
                status: false,
                step: 12
            },
            history: "",
        },
        intervalId: null
    }),

    mounted() {
        //проверяем был ли редирект с госсуслуг
        if(this.$root.state.esiaStatus.isEsia){

            // this.checkStep()
            this.$root.state.esiaStatus.intervalId = setInterval(()=> {
                this.checkStep()
            },500)
        }else {
            this.router.phone.status = true;
        }

    },

    computed: {
        anketa() {
            return this.$root.anketa;
        },
        connector() {
            return this.$root.connector;
        },

        backPrevButtonRules(){
            return this.router.smsPage.status && !this.anketa.codeSMS.success || this.router.auth.status && !this.anketa.codeSMS.success
        },
        state(){
            return this.$root.state
        },
        useStep(){
            return this.$root.state.esiaStatus.usingStep
        },
        step(){
            return this.$root.state.esiaStatus.step
        }
    },
    methods: {
        //данный метод устарел, оставляем эту логику до лучших времен, пока не удалять может пригодиться
        // getClientStatus(urlParams) {
        //     const data = {
        //         client_id: urlParams.get("client"),
        //         new_client_token: urlParams.get("app_id"),
        //         token_timestamp: urlParams.get("ts"),
        //     };
        //
        //     this.connector
        //         .isStatusApplication(data)
        //             .then((response) => {
        //                 console.log('status start', response)
        //                 if (!response.redirect_auth) {
        //                     this.anketa.client_id = urlParams.get("client");
        //                     this.anketa.new_client_token = urlParams.get("app_id");
        //                     this.anketa.token_timestamp = urlParams.get("ts");
        //                     this.router.statusWait.status = true;
        //                     this.anketa.isOpenModal = true;
        //                     this.anketa.fullScreenModal = true;
        //                     this.anketa.withoutToken = true;
        //                 } else {
        //                 // this.router.account = true;
        //                     this.router.phone.status = true;
        //                 }
        //             })
        //             .catch(() => {
        //             // this.router.account = true;
        //             //     console.log('this.step', this.step)
        //             //     console.log('router', this.router)
        //                 console.log('this.step formHtml', this.step);
        //                 if(this.step){
        //                     for(const route in this.router){
        //                         // console.log(this.router[route]);
        //                         if(this.router[route].step === this.step){
        //                             this.router[route].status = true;
        //                         }
        //                     }
        //                 }else {
        //                     this.router.phone.status = true;
        //                 }
        //
        //             });
        // },

        backPrevPage() {
            for (const [key] of Object.entries(this.router)) {
                console.log('key', this.router[key])
                console.log('backPrevPage key', key)
                this.changeRouter(key, this.router.history);
                break;
            }
        },

        changeRouter(prev, next) {
            console.log(prev, next)
            if (prev.length != 0 && next.length != 0) {
                console.log('12', this.router[next], this.router.history);
            for (const k in this.router) {
                    console.log('this.router[k]', this.router[k]);
                if(k !== 'history'){
                    this.router[k].status = false;
                }

            }

            this.router[next].status = true;
            this.router.history = prev;
            } else {
                return;
            }
        },

        checkStep(){
            if(this.step) {
                // this.state.esiaStatus.step = this.step;
                this.router.waitingEsia.status = true;
                if(this.useStep) {
                    for (const route in this.router) {
                        if(route !== 'history'){
                            this.router[route].status = false
                            if (this.router[route].step === this.state.esiaStatus.step) {
                                this.router[route].status = true
                                // clearInterval(this.intervalId);

                            }
                        }

                    }
                }

                // else if(!this.useStep) {
                //     clearInterval(this.$root.state.esiaStatus.intervalId)
                // }
                // else {
                //     clearInterval(this.intervalId);
                //     // for (const route in this.router) {
                //     //         console.log('FormHTML router', `${route}- ${this.router[route].status}`);
                //     //     if(route !== 'history'){
                //     //         this.router[route].status = false
                //     //         if (this.router[route].step === this.state.esiaStatus.step) {
                //     //             this.router[route].status = true
                //     //             clearInterval(this.intervalId);
                //     //
                //     //         }
                //     //     }
                //     // }
                // }


            }
        }
    },

};
</script>

<style lang="scss" scoped>
  .btn_round {
    border-radius: 50%;
    width: 32px;
    height: 32px;
  }
</style>
