<template>
    <div>
        <div v-if="!smsSucceed">
            <div class="main-option-for-app">
                <h1
                    :class="addClass('sms-page_title')"
                    >{{!$root.appType('travelata') && !$root.appType('bankiros') && !$root.appType('southwind') ? 'Подтверждение' : 'Код из СМС'}}
                </h1>
                <p
                    v-if="!$root.appType('travelata') && !$root.appType('bankiros') && !$root.appType('southwind')"
                    class="sms-page_subtitle text-left"
                    >Для зачисления денег введите код из смс
                </p>
                <p
                    v-if="$root.appType('travelata') || $root.appType('bankiros') || $root.appType('southwind')"
                    class="travelata_sms-page_subtitle text-left"
                    >Введите код, отправленный на номер: {{anketa.phone.value}}
                </p>
            </div>

            <v-form @submit.prevent class="sms-form" ref="sms">
                <div class="password-input">

                    <FieldsCard
                        :anketaNames="['codeSMS']"
                        :class="`${$root.state.type()}_code-sms_field`"
                    />
                </div>

                <Timer
                    v-if="!timerEnded"
                    :key="repeatSopd"
                    label="Выслать код повторно через"
                    :seconds="seconds"
                    append="сек."
                    :class="addClass('sopd-timer')"
                    class="text-right"
                    @complete="timerEnded = true"
                    :colorTimerValue="$root.appType('travelata') ? '#707070' : $vuetify.theme.defaults.light.primary"
                    :colorAppend="$root.appType('travelata') ? '#707070' : $vuetify.theme.defaults.light.primary"
                />
                <a v-if="timerEnded && ($root.appType('cash') || $root.appType('auto'))" @click.prevent="resendSms"
                    >Отправить смс повторно
                </a>
                <a
                    v-if="timerEnded && (!$root.appType('cash') && !$root.appType('auto'))"
                    @click.prevent="resendSms"
                    :class="`${$root.state.type()}_resend-code`"
                    class="resend-code"
                    >Выслать код повторно
                </a>

                <p class="sms-code_error-text" :class="!$root.appType('cash') && !$root.appType('auto') ? $root.state.type() : ''" v-show="errorAuthTextApi.status">
                    {{
                        typeof errorAuthTextApi.text !== "object"
                            ? errorAuthTextApi.text
                            : errorAuthTextApi.text.reason
                    }}
                </p>

                <MainButton @click="next" v-if="!$root.appType('cash') && !$root.appType('auto')">
                    Далее
                </MainButton>
            </v-form>
        </div>

        <div class="main-option-for-app" v-else>
            <h1
                :class="$root.appType('vtb') ? 'sms-page_vtb_title' : 'sms-page_title'"
                >Деньги отправлены
            </h1>
            <p class="text-left sms-page_success">Мы отправили деньги на указанные реквизиты</p>
            <a class="sms-page_done-btn" :style="{background: colorOfButton}"  :href="this.anketa.shortLink.value">
                <img src="../../assets/img/done_btn.png" />
            </a>
        </div>
    </div>
</template>

<script>
import FieldsCard from "@/components/FieldsCard";
import Timer from "@/components/controls/Timer";
import MainButton from "@/components/global/MainButton.vue";
import moment from "moment";

export default {
    name: "SmsPage",
    components: {MainButton, FieldsCard, Timer },
    data: () => ({
        timerEnded: false,
        loading: false,
        seconds: 120,
        repeatSopd: 0,
        errorAuthTextApi: {
            status: false,
            text: "",
        },
        exceptionsTypes: ['cash', 'auto', 'nordwind']
    }),
    computed: {
        connector() {
            return this.$root.connector;
        },
        anketa() {
            return this.$root.anketa;
        },
        isNordwind(){
            return this.$root.state.isNordwind()
        },
        smsSucceed: {
            set() {
                return this.anketa.codeSMS.success;
            },
            get() {
                if (this.anketa.codeSMS.success === true) {
                    this.$root[`metrika${this.$root.addYandexMetricGoals()}`].reachGoal("sms");
                    this.$root.mindbox("Подписал заявку");
                }
                if (this.anketa.codeSMS.success === false && this.anketa.codeSMS.errorMessage.length != 0) {
                    if(this.isNordwind){
                        this.$root.metrika96799237.reachGoal("entering_code_error");
                    }else {
                        this.$root.metrika88149184.reachGoal("entering_code_error");
                    }
                    this.showMessage();
                }
                return this.anketa.codeSMS.success;
            },
        },
        colorOfButton(){
            return this.$root.$vuetify.theme.themes.light.primary;
        }

    },
    methods: {
        next() {
            if(this.$refs.sms.validate()) {
                this.anketa.codeSMS.input(this.anketa.codeSMS.value);
            }
        },
        async resendSms() {
            await this.connector
                .isRequestForSMSCode()
                .then((response) => {
                    if (response.success) {
                        const getTime = response.message.dateExpiresOn
                        console.log("getTime", getTime);

                        this.seconds = response.time_left;
                        this.timerEnded = false;
                        this.repeatSopd++;
                    } else {
                        this.errorAuthTextApi.status = true;
                        this.errorAuthTextApi.text = response.message;
                    }
                })
                .catch((error) => {
                    this.errorAuthTextApi.status = true;
                    this.errorAuthTextApi.text = error;
                });
        },

        showMessage() {
            this.errorAuthTextApi.status = true;
            this.errorAuthTextApi.text = this.anketa.codeSMS.errorMessage;
        },
        // setTimeout( window.location.replace(response.shortlink), 35000);

        addClass(cssClass){
            let searchingClass = null
            // console.log('addClass', this.exceptionsTypes.find(this.$root.state.type()))
            if(this.exceptionsTypes.find(el => el === this.$root.state.type())) {
                searchingClass = `${cssClass}`
            }else {
                searchingClass = `${this.$root.state.type()}_${cssClass}`
            }
            return searchingClass
        },

    },
    mounted() {
        console.log('asjudhqyuwgheyqwgeyhqbwehuqweqwe', this.colorOfButton)
        const start = moment('2024-11-11T11:32:00.857');
        const now = moment();
        console.log('start', start);
        console.log('now', now);
        console.log('diff', now.diff(start, 'seconds'));
    }
};
</script>

<style lang="scss">
@import "@/assets/scss/_vtb-variables.scss";
@import "@/assets/scss/_travelata-variables.scss";

.sms-page {
    &_title {
        font-family: Roboto !important;
    }
    &_subtitle {
        font-family: Roboto;
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.5px;
        margin: 20px 0 20px !important;
    }

    //&_done-btn {
    //    text-decoration: none;
    //    display: flex;
    //    justify-content: center;
    //    align-items: center;
    //    background-color: #26a212;
    //    padding: 16px;
    //    border-radius: 12px;
    //}

    &_success {
        font-family: Roboto;
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        margin: 20px 0 20px !important;
    }


    &_vtb_title {
        @include vtb-form-title;
        line-height: 28px;
        color: #2F3441;
    }

}

.vtb {
    &_sms-page_title {
        @include vtb-form-title;
        line-height: 28px;
        color: #2F3441;
    }
}

.travelata {
    &_sms-page_title {
        @include tr-text(24px, 800, 28px);
        font-family: 'OpenSansEB';
        color: #333;
    }

    &_code-sms_field {
        & input {
            color: red !important;
        }
    }

    &_sms-page_subtitle {
        @include tr-text();
        color: #707070;
        font-family: 'OpenSansR';
        margin-top: 12px !important;
    }

    &_sopd-timer {
        @include tr-text(12px, 400, 16px);
        font-family: 'OpenSansR';
        color: #707070;

    }

    &_sms-page_timer_link {
        text-align: right;
        &:hover {
            text-decoration: none;
        }
    }
}
.southwind {
    &_sms-page_title {
        font-family: Roboto;
        font-size: 24px;
        font-weight: 900;
        line-height: 28px;
        letter-spacing: -0.4px;
        color: #0A0A0D;
    }
}


.sms-page_done-btn {
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #26a212;
    padding: 16px;
    border-radius: 12px;
}

.travelata {
    &_code-sms_field {
        & .v-text-field__slot {
            & input {
                @include tr-text(24px, 800, 28px);
                font-family: 'OpenSansEB';
                color: #EC933D !important;
            }

        }
    }
}
.vtb {
    &_code-sms_field {
        & .v-text-field__slot {
            & input {
                @include tr-text(24px, 800, 28px);
                font-family: 'OpenSansEB';
                color: #1976D2 !important;
            }

        }
    }
}

.southwind{
    &_code-sms_field {
        & .v-text-field__slot {
            & input {
                @include tr-text(24px, 800, 28px);
                font-family: 'OpenSansEB';
                color: #D6181E !important;
            }

        }
    }
}
.nordwind{
    &_code-sms_field {
        & .v-text-field__slot {
            & input {
                @include tr-text(24px, 800, 28px);
                font-family: 'OpenSansEB';
                color: #D6181E !important;
            }

        }
    }
}
.resend-code {
    text-align: right;
    display: block;
    margin-top: 3px;
}

.travelata_resend-code {
    @include tr-text(12px, 400, 16px);
    font-family: 'OpenSansR';
    color: #059BCE !important;
}
.bankiros_resend-code {
    font-family: Lato;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: -0.4px;
    color: #388EC3 !important;
}
.nordwind_resend-code {
    @include tr-text(12px, 400, 16px);
    font-family: 'OpenSansR';
    color: #D6181E !important;
}

.vtb_resend-code {
    font-family: VtbRegular;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: -0.4px;
    color: #1976D2 !important;
}
.southwind_resend-code {
    @include tr-text(12px, 400, 16px);
    font-family: 'OpenSansR';
    color: #D6181E !important;
}

.sms-code_error-text {

    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: -0.4px;
    color: #FA656E;
    text-align: left;
    &.nordwind {
        font-family: Roboto !important;
    }
    &.vtb {
        font-family: VtbRegular !important;
    }
    &.travelata {
        font-family: 'OpenSansR' !important;
    }
    &.bankiros {
        font-family: Roboto !important;
    }
    &.southwind {
        font-family: Roboto !important;
    }
}
</style>
